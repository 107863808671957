import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Main/index.tsx";
import Typography from '@material-ui/core/Typography';
import CentreAlign from '@/components/CentreAlign';
import PageTitle from '@/components/PageTitle';
import ContactForm from '@/components/ContactForm';
import Box from '@material-ui/core/Box';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <CentreAlign mdxType="CentreAlign">
      <PageTitle mdxType="PageTitle">
        <Typography variant="h1" style={{
          flexBasis: '100%'
        }} mdxType="Typography">
  Want to learn more?
        </Typography>
        <Typography variant="h5" style={{
          flexBasis: '100%'
        }} mdxType="Typography">
  Let's Talk!
        </Typography>
      </PageTitle>
      <Box mdxType="Box">
        <p>{`Chat to our team to see how else we could help you harness untapped financial
data with our range of aggregation and analysis tools. We can also discuss
tailoring pricing and integration options or custom solutions.`}</p>
      </Box>
    </CentreAlign>
    <Box mdxType="Box">
      <ContactForm mdxType="ContactForm" />
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      